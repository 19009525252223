import { useStore } from 'vuex';
import { ApiResponse } from '@/service/api';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { ListingRequestSource } from '@/store/modules/api';

export type AppNotification = {
  id: number;
  title: string;
  content: string;
  url: string;
  created_at: string;
  is_read: boolean;
}

export type FetchNotificationsModel = ListingRequestSource<AppNotification> & { company: number; o: string };

export const useNotifications = () => {
  const store = useStore();
  const notifications = ref<AppNotification[]>([]);
  const total = ref<number>(0);
  const limit = ref<number>(100);
  const page = ref<number>(1);

  const params = computed(() => ({
    limit: limit.value,
    page: page.value,

  }));

  const fetchNotifications = async () => {
    const { status, response } = await store.dispatch('notifications/fetchNotifications', params.value);

    if (status) {
      notifications.value = response.results;
      total.value = response.count;
    }
  };

  onMounted(async () => {
    await fetchNotifications();
  });

  watch(params, async (value) => {
    await fetchNotifications();
  });

  const markAsRead = (id: number): Promise<ApiResponse<any>> => store.dispatch(
    'notifications/markAsRead', id,
  );

  return {
    total,
    limit,
    page,
    notifications,
    hasUnread: computed(() => store.getters['notifications/hasUnread'] as boolean),
    markAsRead,
    markAllAsRead: async () => {
      await Promise.all(
        notifications.value.map(({ id }) => markAsRead(id)),
      );
    },
  };
};
