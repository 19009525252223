import { useStore } from 'vuex';
import { Company } from '@/hooks/useCompanies';
import {
  ApiResponse, ListingRequestSource, ListingResponse,
} from '@/store/modules/api';
import { ProductionType } from '@/hooks/useConstructor';
import { useApi2 } from '@/hooks/useApi2';
import { useSignal } from '@/hooks/useSignal';

export type CompanyBalance = {
  balance: number;
  id: number;
  income: number;
  outcome: number;
  name_full: string;
}

export type CompanyLicencesActiveCount = {
  active_count: number;
}

export type CompanyLicense = {
  id: number | null;
  price: number;
  days: number;
  count: number;
  neuro_count: number;
  enrichment_cost: number;
  enrichment_count: number;
  end_at: string;
  license?: {
    id: number;
    enrichment_cost: number;
    name: string;
    neuro_count: number;
    price: number;
  };
  additional_services: string[];
};

export type FetchCompanyBalanceModel = {
  id: Company['id'];
}

export type FetchCompanyLicenses = {
  id: Company['id'];
}

export type FetchCompanyBalanceResponse = ApiResponse<CompanyBalance>;
export type FetchCompanyLicensesResponse = ApiResponse<ListingResponse<CompanyLicense>>;
export type FetchCompanyLicencesActiveCount = ApiResponse<CompanyLicencesActiveCount>;

export enum ServiceType {
  pretrial = 'pretrial',
  judicial = 'judicial',
  executive = 'executive',
  general = 'general'
}

export type FinanceServiceHandle = 'SMS'|'VOICE_CALL'|'CREATE_DOC'|'ROSREESTR'|'QUERY_FSSP'
    |'CREATE_CLAIM'|'EMAIL'|'GRAPH'|'MESSENGERS'|'CREATE_DOC_EDS'|'HBKI'|'POCHTA_RU'|'ENRICHMENT'|'CREATE_ISK'|'SMS_COMMISION'

export type FinanceService = {
  id: number;
  handle: FinanceServiceHandle;
  company_id: Company['id'];
  production_type: ServiceType;
  name: string;
  rates: any[];
  unit: string;
}

export type ActiveFinanceService = {
  id: number;
  name: string;
  amount: number;
  count: number;
  active: boolean;
  company_id: Company['id'];
  begin_at: Date;
  end_at: Date;
  service: FinanceService['id'];
  rate: number;
  price: string;
  neuro_count: number;
  exceeding_price: string;
  auto_renewal: boolean;
}

export type FetchAvailableServicesModel = ListingRequestSource<FinanceService>

export type FetchAvailableServicesResponse = ApiResponse<ListingResponse<FinanceService>>;

export type FetchActiveServicesModel = ListingRequestSource<ActiveFinanceService>;

export type FetchActiveServicesResponse = ApiResponse<ListingResponse<ActiveFinanceService>>;

export type BuyServiceModel = {
  company_id: number;
  rate: number;
  amount: number;
  begin_at: string;
}

export type BuyLicenseModel = {
  id: number;
  licenseId: number;
}

export type ProlongActiveServiceModel = {
  company_id: number;
  id: FinanceService['id'];
}

export type UpdateActiveProductionTypesModel = {
  company_id: Company['id'];
  production_types: Array<ProductionType>;
}

export const useFinance = () => {
  const store = useStore();
  const api = useApi2();
  const { dispatchSignal } = useSignal();

  const fetchCompanyBalance = async (payload: FetchCompanyBalanceModel): Promise<FetchCompanyBalanceResponse> => {
    const { status, response } = await store.dispatch('finance/fetchCompanyBalance', payload);
    return { status, response };
  };

  const fetchAvailableTariffs = async (): Promise<FetchCompanyLicensesResponse> => {
    const { status, response } = await store.dispatch('finance/fetchAvailableTariffs');
    response.results.forEach((e: any) => {
      e.enrichment_cost = +e.enrichment_cost;
      e.price = +e.price;
    });
    return { status, response };
  };

  const fetchCompanyLicenses = async (payload: FetchCompanyLicenses): Promise<FetchCompanyLicensesResponse> => {
    const { status, response } = await store.dispatch('finance/fetchCompanyLicenses', payload);
    response.results.forEach((e: any) => {
      if (e.license) {
        e.license.price = +e.license.price;
        e.license.enrichment_cost = +e.license.enrichment_cost;
        e.price = e.license.price;
        e.name = e.license.name;
        e.enrichment_cost = e.license.enrichment_cost;
      }
    });
    return { status, response };
  };

  const fetchCompanyLicensesActiveCount = async (payload: FetchCompanyLicenses): Promise<FetchCompanyLicencesActiveCount> => {
    const { status, response } = await store.dispatch('finance/fetchCompanyLicensesActiveCount', payload);
    return { status, response };
  };

  const companyLicenseBuy = async (payload: BuyLicenseModel) => {
    const { status, response } = await store.dispatch('finance/companyLicenseBuy', payload);
    return { status, response };
  };

  return {
    fetchCompanyBalance,
    fetchAvailableTariffs,
    fetchCompanyLicenses,
    fetchCompanyLicensesActiveCount,
    companyLicenseBuy,
  };
};
